<template>
  <div>
    <canvas id="ChartEngagementProgress"></canvas>
  </div>
</template>

<script>
import Chart from 'chart.js';

export default {
  name: 'ChartEngagementProgress',
  props: ['data', 'backgroundColors', 'labels'],
  data() {
    return {};
  },
  methods: {},

  mounted() {
    let chartOptions = {
      type: 'horizontalBar',
      data: {
        labels: this.labels,
        datasets: [
          {
            label: ' engagements with progress',
            data: this.data[0],
            fill: this.fill,
            backgroundColor: this.backgroundColors[0] ? this.backgroundColors[0] : '#1f97d1',
            borderColor: 'rgba(232,232,232,1)',
            borderWidth: this.borderWidth ? this.borderWidth : 1,
          },
          {
            label: 'engagements with no change',
            data: this.data[1],
            fill: this.fill,
            backgroundColor: 'rgb(232, 232, 232)',
            borderColor: 'rgba(232,232,232,1)',
            borderWidth: this.borderWidth ? this.borderWidth : 1,
          },
        ],
      },
      options: {
        legend: {
          display: false,
          labels: {
            fontColor: 'rgba(0,0,0)',
            fontStyle: 'bold',
            size: 4,
            fontSize: 12,
          },
        },
        tooltips: {
          callbacks: {
            label: function(tooltipItem, data) {
              var dataset = data.datasets[tooltipItem.datasetIndex];
              return ' ' + tooltipItem.value + ' ' + dataset.label;
            },
          },
        },
        devicePixelRatio: 1.5,
        maintainAspectRatio: true,
        scales: {
          xAxes: [
            {
              stacked: true,
              gridLines: {
                color: 'rgba(255,255,255)',
                borderColor: 'rgba(255,255,255)',
                lineWidth: 0,
                display: false,
              },
            },
          ],
          yAxes: [
            {
              stacked: true,
              gridLines: {
                color: 'rgba(255,255,255)',
                borderColor: 'rgba(255,255,255)',
                display: false,
              },
            },
          ],
        },
      },
    };
    const ctx = document.getElementById('ChartEngagementProgress');
    new Chart(ctx, chartOptions);
  },
};
</script>

<style scoped>
#ChartESGCategories {
  width: 100%;
}
</style>
