<template>
  <div class="box">
    <h2 class="mb-1">{{ module.title }}</h2>
    <p class="p-block">{{ module.info }}</p>

    <div class="flex parent mt-3">
      <div class="child left flex column justify-space-between align-start">
        <div class="table mb-1">
          <div class="table-header justify-space-between pb-1 pl-1 pr-1">
            <p>Country</p>
            <p>Number of Engagements</p>
            <p>Percentage</p>
          </div>
          <div v-if="showTopFiveCountries">
            <div
              class="table-row mt-1 pb-1 pl-1 pr-1"
              v-for="(country, index) in this.sortCountries.slice(0, 5)"
              :class="{
                noBorderBottom: index === 4,
                paddingFirstRow: index === 0,
              }"
              :key="index"
            >
              <p>
                <img class="flag mr-05" :src="require(`../../assets/flags/${formatCountryName(country.name)}.png`)" alt="flag" />
                {{ country.name }}
              </p>
              <p>{{ country.count }}</p>
              <p>{{ `${parseFloat(country.perc).toFixed(1)} %` }}</p>
            </div>
          </div>
          <div v-else>
            <div
              class="table-row mt-1 pb-1 pl-1 pr-1"
              v-for="(country, index) in this.sortCountries.slice(0, 20)"
              :class="{ paddingFirstRow: index === 0, noBorderBottom: index === sortCountries.length - 1 }"
              :key="index"
            >
              <p>
                <img class="flag mr-05" :src="require(`../../assets/flags/${formatCountryName(country.name)}.png`)" alt="flag" />
                {{ country.name }}
              </p>
              <p>{{ country.count }}</p>
              <p>{{ `${parseFloat(country.perc).toFixed(1)} %` }}</p>
            </div>
          </div>

          <div class="info-more-20 flex mt-1 pl-1 pb-1" v-if="sortCountries.length > 20">
            <img class="mr-02" src="../../assets/info.svg" alt="info" />
            <p>Showing top 20</p>
          </div>
        </div>

        <button @click="toggleMoreCountries" v-if="sortCountries.length > 5" class="esgaia-btn">{{ `Show ${showTopFiveCountries ? 'more' : 'less'}` }}</button>
      </div>
      <div class="child right ml-1">
        <world-heat-map
          :country_data="this.module.data.countries.data"
          :countries="this.module.data.countries.collection"
          :colors="this.module.colors"
        ></world-heat-map>
      </div>
    </div>
  </div>
</template>

<script>
/* import MapChart from "vue-map-chart"; */
import WorldHeatMap from '../WorldHeatMap';

export default {
  components: { WorldHeatMap },

  props: ['module'],
  data() {
    return {
      showTopFiveCountries: true,
    };
  },
  computed: {
    sortCountries() {
      const countries = this.module.data.countries.collection;
      countries.sort((firstCountry, secondCountry) => secondCountry.count - firstCountry.count);

      const sortedCountries = countries.filter((country) => country.count > 0);
      return sortedCountries;
    },
  },
  methods: {
    toggleMoreCountries() {
      this.showTopFiveCountries = !this.showTopFiveCountries;
    },
    formatCountryName(country) {
      const countryName = country.toLowerCase();
      return countryName.replace(/\s/g, '-');
    },
  },
};
</script>

<style scoped lang="scss">
.parent {
  gap: 2rem;
}

.left {
  box-sizing: border-box;
  width: 40%;
}

.right {
  width: 60%;
  align-self: start;
}

.table {
  width: 95%;
  margin-left: auto;
  margin-right: auto;
}

.table-header {
  display: flex;
  border-bottom: 2px solid #1f97d1;
  > p {
    width: 30%;
    display: flex;
    text-align: center;
    justify-content: center;
    padding-right: 0.5rem;
  }
  :first-child {
    width: 40%;
    justify-content: flex-start;
  }
  :last-child {
    text-align: right;
    justify-content: flex-end;
    padding-right: 0;
  }
}
.table-row {
  display: flex;
  border-bottom: 1px solid #eaeaea;
  margin-left: auto;
  margin-right: auto;
  &.noBorderBottom {
    border-bottom: none;
  }
  &.paddingFirstRow {
    margin-top: 1.5rem;
  }
  > p {
    width: 30%;
    display: flex;
    text-align: center;
    justify-content: center;
  }
  :first-child {
    width: 40%;
    text-align: left;
    justify-content: flex-start;
  }
  :last-child {
    justify-content: flex-end;
  }
}

.flag {
  width: 1rem !important;
  height: 1rem !important;
  margin-top: 0;
}

.esgaia-button {
  margin-right: auto;
}

.map {
  width: 100%;
  height: auto;
  margin-top: auto;
}

.info-more-20 {
  width: 95%;
  p {
    font-size: 12px;
    color: #999999;
  }
  img {
    height: 1rem;
    width: 1rem;
  }
}
/* Screens smaller than 1024px */
@media only screen and (max-width: 64em) {
  .parent {
    flex-wrap: wrap-reverse;
    gap: 1rem;
  }
  .table {
    margin: 0;
    width: 100%;
  }

  .child {
    width: 100%;
  }
}

/* Screens smaller than 368px */
@media only screen and (max-width: 23em) {
  .table-header {
    padding-left: 0;
    padding-right: 0;

    > p {
      font-size: 12px;
      width: 33%;
    }
  }
  .table-row {
    padding-left: 0;
    padding-right: 0;
    > p {
      font-size: 12px;
    }
  }
}
</style>
