<template>
  <nav class="container" id="modules-dropdown">
    <div v-if="modules && modules.length > 0" class="toggle-btn flex" @click="toggleModulesBar">
      <p>{{ this.selectedModule ? this.selectedModule : this.modules[0].title }}</p>
      <div class="arrow-container"><i class="arrow" :class="{ arrowUp: showModulesDropdown }"></i></div>
    </div>
    <div v-if="showModulesDropdown" class="dropdown" :class="{ show: showModulesDropdown }">
      <a v-for="(module, index) in modules" :key="index" @click="goToModule(module)" :href="`#${module.slug}`" class="pb-05 pt-05">
        {{ module.title }}
      </a>
    </div>
  </nav>
</template>

<script>
export default {
  props: ['modules', 'elementOnTopOfView'],
  data() {
    return {
      activeLink: false,
      showModulesDropdown: false,
      selectedModule: false,
    };
  },
  watch: {
    elementOnTopOfView: function() {
      this.selectedModule = this.elementOnTopOfView;
    },
  },
  methods: {
    toggleModulesBar() {
      this.showModulesDropdown = !this.showModulesDropdown;
    },
    goToModule(module) {
      document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
        anchor.addEventListener('click', function(e) {
          e.preventDefault();
          document.querySelector(this.getAttribute('href')).scrollIntoView({ behavior: 'smooth' });
        });
      });
      /* If the user click on a link in modules dropdown -close dropdown */
      if (this.showModulesDropdown) {
        this.showModulesDropdown = false;
      }
      this.selectedModule = module.title;
    },
    onClickOutside: function(e) {
      let dropdownElement = document.getElementById('modules-dropdown');
      let targetElement = e.target; // clicked element
      do {
        /* If the clicked element is dropdown: do nothing/return */
        if (targetElement === dropdownElement) {
          return;
        }
        /* Set the clicked elements parentNode to targetElement to check if it is the dropdown) */
        targetElement = targetElement.parentNode;
      } /* Do this check for the clicked element and all its parentNodes */ while (targetElement);
      /* If the clicked element and its parent is not the dropdown: check if dropdown is open, if true: close it */
      if (this.showModulesDropdown === true) {
        this.showModulesDropdown = false;
      }
    },
  },
  mounted: function() {
    window.addEventListener('click', this.onClickOutside);
    /* this.moduleTitle = this.elementOnTopOfView; */
  },
  beforeDestroy() {
    window.removeEventListener('click', this.onClickOutside);
  },
};
</script>

<style lang="scss" scoped>
.container {
  display: inline-block;
}
.toggle-btn {
  text-align: right;
  justify-content: flex-end;
  align-items: center;
  text-decoration: none;
  white-space: nowrap;
  color: grey;
  cursor: pointer;
  .arrow-container {
    position: relative;
    margin: 0 10px;
    height: 10px;
    .arrow {
      position: absolute;
      top: 0px;
      border: solid #999999;
      border-width: 0 2px 2px 0;
      display: block;
      padding: 3px;
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      &.arrowUp {
        transform: rotate(225deg);
        top: 4px;
      }
    }
  }
  &:hover {
    color: #000000;
    .arrow-container {
      .arrow {
        border: solid #000000;
        border-width: 0 2px 2px 0;
      }
      &.arrowUp {
        border: solid #000000;
        border-width: 0 2px 2px 0;
      }
    }
  }
}
.dropdown {
  display: none;
  position: absolute;
  right: 0px;
  flex-direction: column;
  z-index: 10;
  background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 1;
  padding: 0.7rem 1.5rem 1rem 1.5rem;
  border-radius: 0.5rem;
  margin: 0.75rem auto 1.25rem auto;
  box-sizing: border-box;

  box-shadow: 0em 0.125em 0.625em #dbdbdb;

  &.show {
    display: flex;
  }
}
/* Screens smaller than 768px */
@media only screen and (max-width: 950px) {
  .toggle-btn {
    display: none;
  }
}
</style>
