<template>
  <div class="filters flex mt-1-05 mr-5">
    <div class="child">
      <!-- FROM DATE -->
      <div class="filter flex mr-1">
        <label class="mr-1 ml-02" for="from_date">From</label>
        <input class="form-control mr-05" type="date" name="from_date" v-model="filter.from_date" />
      </div>
      <!-- TO DATE -->
      <div class="filter flex mr-1">
        <label class="mr-1 ml-02" for="to_date">To</label>
        <input class="form-control mr-05" type="date" name="to_date" v-model="filter.to_date" />
      </div>
    </div>
    <div class="child last">
      <!-- FUNDS -->
      <multiselect
        class="multiselect mr-1"
        name="funds"
        :options="funds"
        v-model="funds_array"
        label="name"
        id="funds"
        value="id"
        track-by="id"
        :limit="1"
        :multiple="true"
        placeholder="All funds"
        @input="onChangeFund"
      >
      </multiselect>
      <!-- BUTTON -->
      <button @click="applyFilter" class="esgaia-btn green">Apply filter</button>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';

export default {
  name: 'Filter-bar',
  components: { Multiselect },
  props: ['funds'],
  data() {
    return {
      value: null,
      allFunds: [],
      funds_array: [],
      filter: {
        from_date: this.getFirstYearDate(),
        to_date: this.getTodaysDate(),
        funds: [],
        ongoing_during_period: true,
      },
    };
  },
  methods: {
    applyFilter() {
      this.$emit('changeFilter', this.filter);
    },
    getFirstYearDate: function() {
      var today = new Date();
      var yyyy = today.getFullYear();

      return yyyy + '-01-01';
    },
    getTodaysDate: function() {
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, '0');
      var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      var yyyy = today.getFullYear();

      return yyyy + '-' + mm + '-' + dd;
    },
    onChangeFund() {
      let _this = this;
      _this.filter.funds = _this.funds_array.map((x) => x.id);
    },
    onClickOutside: function(e) {
      let dropdownElement = document.getElementById('modules-dropdown');
      let targetElement = e.target; // clicked element
      do {
        /* If the clicked element is dropdown: do nothing/return */
        if (targetElement === dropdownElement) {
          return;
        }
        /* Set the clicked elements parentNode to targetElement to check if it is the dropdown) */
        targetElement = targetElement.parentNode;
      } /* Do this check for the clicked element and all its parentNodes */ while (targetElement);
      /* If the clicked element and its parent is not the dropdown: check if dropdown is open, if true: close it */
      if (this.showModulesDropdown === true) {
        this.showModulesDropdown = false;
      }
    },
  },
  mounted() {
    this.applyFilter();

    window.addEventListener('click', this.onClickOutside);
  },
  beforeDestroy() {
    window.removeEventListener('click', this.onClickOutside);
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped lang="scss">
.filters {
  width: 100% !important;
  align-items: center;
  justify-content: flex-start;
}
.child {
  display: flex;
  align-items: center;

  &.last {
    width: 100%;
  }
}
.filter {
  box-sizing: border-box;
  height: 2.5625rem;
  width: 250px;
  align-items: center;
  border: 1px solid rgb(226, 226, 226);
  padding: 0.3125rem;
  border-radius: 0.3125rem;
  label {
    font-size: 0.8125rem;
    margin-top: 0.1875rem;
    border: 1px solid transparent !important;
    outline: none;
    box-shadow: none !important;
  }
  input {
    color: #adadad;
    width: 100%;
  }
}

.form-control {
  background-color: transparent;
  border: none;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 5px;
  outline: none;
  box-shadow: none !important;
}

.multiselect {
  width: 350px;
}

button {
  margin-top: 1px;
  margin-left: auto !important;
}

/* Screens smaller than 1102px */
@media only screen and (max-width: 1102px) {
  .filter {
    width: 200px;
  }
  .multiselect {
    width: 300px;
  }
}

/* Screens smaller than 950px */
@media only screen and (max-width: 950px) {
  .filters {
    flex-wrap: wrap;
  }
  .child {
    &.last {
      margin-top: 1rem;
      width: 100%;
    }
  }
  .filter {
    width: 220px;
  }
  .multiselect {
    width: calc(440px + 1rem);
  }
  button {
    margin-top: 1px;
  }
}

/* Screens smaller than 534px */
@media only screen and (max-width: 534px) {
  .child {
    flex-wrap: wrap;
    width: 100%;
    &.last {
      margin-top: 0;
      width: 100%;
    }
    > * {
      width: 100%;
      margin: 1rem 0 0 0 !important;
    }
  }
}
</style>
