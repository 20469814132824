<template>
  <div>
    <canvas id="ChartESGCategories"></canvas>
  </div>
</template>

<script>
import Chart from 'chart.js';

export default {
  name: 'ChartESGCategories',
  props: ['data'],
  data() {
    return {
      totalAmount: this.data.data.E.amount + this.data.data.S.amount + this.data.data.G.amount,
      amountE: this.data.data.E.amount,
      amountS: this.data.data.S.amount,
      amountG: this.data.data.G.amount,
    };
  },
  methods: {
    calcPercent(amount) {
      let percent;
      percent = Math.round(100 * (amount / this.totalAmount));
      return percent;
    },
  },

  mounted() {
    this.calcPercent();
    Chart.defaults.global.defaultFontFamily = "'Open Sans', sans-serif";
    let chartOptions = {
      type: 'doughnut',
      data: {
        labels: [this.data.data.E.label + ' (%)', this.data.data.S.label + ' (%) ', this.data.data.G.label + ' (%)'],
        datasets: [
          {
            label: 'ESG Categories',
            data: [this.calcPercent(this.amountE), this.calcPercent(this.amountS), this.calcPercent(this.amountG)],
            backgroundColor: [
              this.data.colors[0] ? this.data.colors[0] : '#a3d373',
              this.data.colors[1] ? this.data.colors[1] : '#479cd1',
              this.data.colors[2] ? this.data.colors[2] : '#e9c46a',
            ],
            hoverOffset: 4,
          },
        ],
      },

      options: {
        legend: {
          position: 'bottom',
          labels: {
            padding: 10,
            defaultFontFamily: "'Open Sans', sans-serif",
          },
        },
        responsive: true,
        maintainAspectRatio: true,
      },
    };
    const ctx = document.getElementById('ChartESGCategories');
    new Chart(ctx, chartOptions);
  },
};
</script>

<style scoped>
#ChartESGCategories {
  width: 100%;
}
</style>
