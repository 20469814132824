<template>
  <div class="box flex column" id="header-element">
    <div v-if="previewToken" class="preview-small-screens"><span>PREVIEW</span></div>
    <div class="flex justify-space-between">
      <h1 class="mr-1 title"><span v-if="previewToken" class="preview mr-1">PREVIEW </span>{{ appTitle }}</h1>
      <Navigation-bar :modules="modules" :elementOnTopOfView="elementOnTopOfView" />
      <div class="toogle-filterbar-btn flex" @click="toggleFilterBar">
        <p>Filters</p>
        <div class="arrow-container"><i class="arrow" :class="{ arrowUp: showFilterBar }"></i></div>
      </div>
    </div>
    <Filter-bar class="filter-bar" id="filter-bar" :class="{ show: showFilterBar }" :funds="funds" @changeFilter="changeFilter($event)" />
  </div>
</template>

<script>
import FilterBar from './FilterBar.vue';
import NavigationBar from './NavigationBar.vue';
export default {
  name: 'App-header',
  components: {
    FilterBar,
    NavigationBar,
  },
  props: ['modules', 'funds', 'elementOnTopOfView'],
  data() {
    return {
      appTitle: 'Engagement data',
      showFilterBar: false,
      previewToken: null,
    };
  },
  methods: {
    toggleFilterBar() {
      this.showFilterBar = !this.showFilterBar;
    },
    changeFilter(data) {
      this.$emit('changeFilter', data);
      if (this.showFilterBar) {
        this.showFilterBar = false;
      }
    },
    onClickOutside: function(e) {
      let dropdownElement = document.getElementById('filter-title');
      let headerElement = document.getElementById('header-element');
      let targetElement = e.target; // clicked element
      do {
        /* If the clicked element is dropdown: do nothing/return */
        if (targetElement === dropdownElement || targetElement === headerElement) {
          return;
        }
        /* Set the clicked elements parentNode to targetElement to check if it is the dropdown) */
        targetElement = targetElement.parentNode;
      } /* Do this check for the clicked element and all its parentNodes */ while (targetElement);
      /* If the clicked element and its parent is not the dropdown: check if dropdown is open, if true: close it */
      if (this.showFilterBar === true) {
        this.showFilterBar = false;
      }
    },
  },
  mounted() {
    // to check if the URL contains a preview token
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    let preview_token = urlParams.get('preview_token');
    if (preview_token) {
      this.previewToken = preview_token;
    }
    window.addEventListener('click', this.onClickOutside);
  },
  beforeDestroy() {
    window.removeEventListener('click', this.onClickOutside);
  },
};
</script>

<style scoped lang="scss">
.preview {
  background-color: #2e698e;
  padding: 5px 15px 5px 15px;
  border-radius: 10px;
  color: white;
}
.preview-small-screens {
  display: none;
}
.box {
  margin-left: auto;
  margin-right: auto;
  left: 0.5rem;
  right: 0.5rem;
}
.toogle-filterbar-btn {
  display: none;
  text-decoration: none;
  white-space: nowrap;
  color: grey;
  cursor: pointer;
  text-align: right;
  justify-content: flex-end;
  align-items: center;
  text-decoration: none;
  white-space: nowrap;
  color: grey;
  cursor: pointer;
  .arrow-container {
    position: relative;
    width: 10px;
    margin: 0 10px;
    height: 10px;
    .arrow {
      position: absolute;
      top: 0px;
      border: solid #999999;
      border-width: 0 2px 2px 0;
      display: block;
      padding: 3px;
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      &.arrowUp {
        transform: rotate(225deg);
        top: 4px;
      }
    }
  }
  &:hover {
    color: #000000;
    .arrow-container {
      .arrow {
        border: solid #000000;
        border-width: 0 2px 2px 0;
      }
      &.arrowUp {
        border: solid #000000;
        border-width: 0 2px 2px 0;
      }
    }
  }
}

/* Screens smaller than 768px */
@media only screen and (max-width: 950px) {
  .toogle-filterbar-btn {
    display: flex;
    &:hover {
      cursor: pointer;
    }
  }
  .arrowUp {
    transform: rotate(180deg);
  }

  .filter-bar {
    display: none;
    &.show {
      display: flex;
    }
  }
}

/* Screens smaller than 400px */
@media only screen and (max-width: 400px) {
  .preview {
    display: none;
  }
  .preview-small-screens {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 25px;
    text-align: center;
    background-color: #2e698e;
    color: white;
    > span {
      line-height: 25px;
    }
  }
}
</style>
