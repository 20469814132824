<template>
  <div class="box">
    <h2 class="mb-1">{{ module.title }}</h2>
    <div class="flex parent mt-2 mb-2">
      <div class="child left">
        <div v-if="showTopFiveIssues">
          <div
            class="issue flex justify-space-between pb-1 pl-1"
            :class="{ 'mt-1': index > 0, noBorderBottom: index === 4 }"
            v-for="(issue, index) in sortIssues.slice(0, 5)"
            :key="index"
          >
            <span class="mr-1">{{ issue.esg_issue }}</span>
            <p class="pr-1 percentage">
              {{ `${issue.percent} %` }}
            </p>
          </div>
        </div>
        <div v-else>
          <div
            class="issue flex justify-space-between pb-1 pl-1"
            :class="{
              'mt-1': index > 0,
              noBorderBottom: index === sortIssues.length - 1,
            }"
            v-for="(issue, index) in sortIssues.slice(0, 20)"
            :key="index"
          >
            <span class="mr-1">{{ issue.esg_issue }}</span>
            <p class="pr-1 percentage">
              {{ `${issue.percent} %` }}
            </p>
          </div>
        </div>
        <div class="info-more-20 flex mt-1 pl-1 pb-1" v-if="sortIssues.length > 20">
          <img class="mr-02" src="../../assets/info.svg" alt="info" />
          <p>Showing top 20</p>
        </div>
      </div>
      <div class="child right">
        <p class="p-block">{{ module.info }}</p>
      </div>
    </div>

    <button @click="toggleMoreIssues" v-if="sortIssues.length > 5" class="esgaia-btn">
      {{ `Show ${showTopFiveIssues ? 'more' : 'less'}` }}
    </button>
  </div>
</template>

<script>
export default {
  props: ['module'],
  data() {
    return {
      showTopFiveIssues: true,
    };
  },
  computed: {
    sortIssues() {
      //calcuate total amount of topics
      let totalAmount = 0;
      for (let i = 0; i < this.module.statistics.length; i++) {
        totalAmount += this.module.statistics[i].amount;
      }
      //calcuate percent for each statitic
      this.module.statistics.forEach((s) => (s.percent = parseFloat(100 * (s.amount / totalAmount)).toFixed(1)));
      //sort higest to lowest percent
      const sortedIssues = this.module.statistics;
      sortedIssues.sort((firstIssue, secondIssue) => secondIssue.percent - firstIssue.percent);

      return sortedIssues;
    },
  },
  methods: {
    toggleMoreIssues() {
      this.showTopFiveIssues = !this.showTopFiveIssues;
    },
  },
};
</script>

<style scoped lang="scss">
.parent {
  gap: 2rem;
}

.left {
  width: 40%;
}

.right {
  width: 60%;
  box-sizing: border-box;
}

.issue {
  border-bottom: 1px solid #eaeaea;

  margin-left: auto;
  margin-right: auto;
  &.noBorderBottom {
    border-bottom: none;
  }
}
.info-more-20 {
  width: 95%;
  p {
    font-size: 12px;
    color: #999999;
  }
  img {
    height: 1rem;
    width: 1rem;
  }
}

/* Screens smaller than 1024px */
@media only screen and (max-width: 64em) {
  .parent {
    flex-wrap: wrap;
    gap: 3rem;
  }
  .child {
    width: 100%;
  }

  .right {
    margin-bottom: 1rem;
    padding-left: 0;
  }
  .issue {
    margin-left: 0;
  }
}

/* Screens smaller than 400px */
@media only screen and (max-width: 400px) {
  .issue {
    padding-left: 0 !important;
  }
  .percentage {
    padding-right: 0 !important;
  }
  /* .sdg {
    padding-left: 0 !important;
  }
  img {
    width: 2rem;
    height: 2rem;
  } */
}
</style>
