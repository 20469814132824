<template>
  <div id="app">
    <div id="push-footer">
      <div class="gradient-white-bg z-index-1"></div>

      <App-header
        :modules="orgData.modules"
        :funds="orgData.funds"
        :elementOnTopOfView="elementOnTopOfView"
        class="fixed z-index-2 mt-05"
        @changeFilter="changeFilter($event)"
      />

      <div class="loading center pr-1" v-if="loading">
        <spinner small="true"></spinner>
      </div>
      <div class="loading center pr-1" v-if="errorText">
        {{ errorText }}
      </div>

      <div class="anchor-block-wrapper" v-for="(m, index) in orgData.modules" v-bind:key="index">
        <div class="anchor-block" :id="m.slug" :class="m.title"></div>
        <component v-if="showComponents" v-bind:is="m.component" :class="[m.component, 'pb-3', { 'mt-behind-header': index === 0 }]" :module="m"></component>
      </div>
      <App-footer id="footer" :center="loading" v-if="!errorText" />
    </div>
  </div>
</template>
<script>
import AppHeader from './components/header/AppHeader.vue';
import FilterBar from './components/header/FilterBar.vue';
import AppFooter from './components/AppFooter.vue';
import NumberOfEngagements from './components/modules/NumberOfEngagements.vue';
import ESGCategories from './components/modules/ESGCategories.vue';
import EngagementProgress from './components/modules/EngagementProgress.vue';
import EngagementCases from './components/modules/EngagementCases.vue';
import ESGIssues from './components/modules/ESGIssues.vue';
import WorldMap from './components/modules/WorldMap.vue';
import PaiIndicators from './components/modules/PaiIndicators.vue';
import SustainableDevelopmentGoals from './components/modules/SustainableDevelopmentGoals.vue';
import Spinner from './components/Spinner.vue';

export default {
  name: 'App',
  components: {
    AppHeader,
    FilterBar,
    AppFooter,
    NumberOfEngagements,
    ESGCategories,
    ESGIssues,
    WorldMap,
    PaiIndicators,
    SustainableDevelopmentGoals,
    Spinner,
    EngagementProgress,
    EngagementCases,
  },
  data() {
    return {
      orgData: {
        modules: [],
        funds: [],
      },
      errorText: null,
      orgId: null,
      showComponents: false,
      filter: {
        from_date: null,
        to_date: null,
        funds: null,
        ongoing_during_period: true,
        preview_token: null,
      },
      loading: true,
      elementOnTopOfView: null,
    };
  },
  methods: {
    getData(filterData) {
      const axios = require('axios');
      this.showComponents = false;
      let _this = this;
      this.loading = true;

      // to check if the URL contains a preview token
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      let preview_token = urlParams.get('preview_token');
      if (preview_token) {
        filterData.preview_token = preview_token;
      }

      axios
        .get(`https://engagement.glasslewis.com/api/embed/engagements/${_this.orgId}`, {
          params: filterData,
        })
        .then(function(response) {
          //Handle success
          _this.showComponents = true;
          _this.orgData = response.data.data;
          _this.loading = false;
          _this.errorText = null;
        })
        .catch(function(error) {
          //Handle error
          console.log(error);
          _this.loading = false;
          _this.errorText = "We're sorry, we could not fetch the data for this live disclosure.";
        });
    },
    changeFilter(filterData) {
      this.getData(filterData);
    },
    findGetParameter(id) {
      //Get the organisation id from url and store it in this.orgId
      let tmp = [];
      let _this = this;
      location.search
        .substr(1)
        .split('&')
        .forEach(function(item) {
          tmp = item.split('=');
          if (tmp[0] === id) _this.orgId = decodeURIComponent(tmp[1]);
        });
    },
    onScroll: function() {
      let documentHeight = document.body.scrollHeight;
      let currentScroll = window.scrollY + window.innerHeight;
      let modifier = 100;
      // If the user is 150px from the bottom, select the last module
      if (currentScroll + modifier > documentHeight) {
        let lastEl = document.getElementsByClassName('anchor-block');
        let el = lastEl[lastEl.length - 1];
        const moduleName = el.className.replace('anchor-block', '');
        this.elementOnTopOfView = moduleName;
      }
      // Else select the module that is in top of view
      else {
        document.querySelectorAll('.anchor-block').forEach((anchor) => {
          let el = anchor;
          let viewportOffset = el.getBoundingClientRect();
          // these are relative to the viewport
          let top = viewportOffset.top;
          if (top >= 0 && top < 200) {
            const moduleName = el.className.replace('anchor-block', '');
            this.elementOnTopOfView = moduleName;
          }
        });
      }
    },
  },
  created() {
    this.findGetParameter('id');
  },
  mounted: function() {
    window.addEventListener('scroll', this.onScroll);
  },
  beforeDestroy() {
    window.addEventListener('scroll', this.onScroll);
  },
};
</script>


<style lang="scss">
/* To converte rem -> px, unit in rem * 16 = unit in px */
/* To converte px -> rem, unit in px / 16 = unit in rem */
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

* {
  font-family: 'Open Sans', sans-serif;
  margin: 0;
  padding: 0;
  letter-spacing: 0.01rem;
}
.loading {
  padding: 250px 0px 0px 0px;
  text-align: center;
}
.center {
}
#app {
  height: 100vh;
  margin: auto;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  display: flex;
  flex-direction: column;
}

#push-footer {
  flex: 1 0 auto;
}

.box {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0em 0.125em 0.625em #dbdbdb;
  opacity: 1;
  padding: 1.875rem;
  border-radius: 0.5rem;
  margin: 0 auto 1.25rem auto;
  box-sizing: border-box;
}

//HEADER
.mt-behind-header {
  margin-top: 10.6rem;
}
.gradient-white-bg {
  height: 9rem;
  background-image: linear-gradient(white, rgba(255, 255, 255, 0));
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

/* MULTISELECT */
// fix multiselect weird height when using a placeholder
.multiselect__placeholder {
  display: inline-block !important;
  margin-bottom: 0px !important;
  padding-top: 2px !important;

  /* Style the space between text and border left */
  margin-left: 5px;
}

// override default multiselect styles
.multiselect__option--highlight {
  background: #1f97d1 !important; /* #41b883 */
  color: #ffffff !important;
}

.multiselect__tags {
  color: #ffffff !important;
  /* Style the space between text and border left */
  padding-left: 5px !important;
}

.multiselect__option--highlight:after {
  background: #1f97d1 !important; /* #41b883 */
  color: #ffffff !important;
}

.multiselect__option--selected.multiselect__option--highlight {
  background: #ff6f59 !important; /* #ff6a6a */
  color: #ffffff !important;
  font-weight: 500 !important;
}

.multiselect__option--selected.multiselect__option--highlight:after {
  background: #ff6f59 !important; /* #ff6a6a */
  color: #ffffff !important;
}

.multiselect__option--selected,
.multiselect__strong {
  font-weight: 500 !important;
  color: #ffffff !important;
}

.multiselect__tag {
  background: #1f97d1 !important; /* #41b883 */
  border: 1px #0188cc !important;
  color: #ffffff !important;
  /* Style the space between tag and border left */
  margin: 0 5px 5px 5px !important;
}
.multiselect__tag > span {
  color: #ffffff !important;
}

.multiselect__tag-icon:focus,
.multiselect__tag-icon:hover {
  background: #0188cc !important;
}

.multiselect__tag-icon:after {
  color: #434d65 !important; /* #266d4d */
}

.multiselect__tag-icon:focus:after,
.multiselect__tag-icon:hover:after {
  color: #ffffff !important;
}

.multiselect,
.multiselect__input,
.multiselect__single {
  font-size: 14px !important;
}

.multiselect__input,
.multiselect__single {
  padding-top: 2px !important;
}

.fixed {
  position: fixed;
}

.z-index-1 {
  z-index: 1;
}
.z-index-2 {
  z-index: 2;
}

//MODULES
.anchor-block-wrapper {
  position: relative;
}
.anchor-block {
  width: 1rem;
  top: -10.6rem;
  height: 1rem;
  display: inline-block;
  position: absolute;
}

//FOOTER
#footer {
  flex-shrink: 0;
}

//FONTS
h1,
h2 {
  font-size: .875rem;
  letter-spacing: 0.02rem;
}

p,
span,
input,
a,
button,
label {
  font-size: 0.875rem;
}

.p-block {
  font-family: 'Open Sans', sans-serif;
  line-height: 1.8;
  letter-spacing: 0.02rem;
  font-size: 0.8rem;
  white-space: pre-line;
}

a {
  text-decoration: none;
  white-space: nowrap;
  color: grey;
  cursor: pointer;
  &:hover {
    color: #212529;
  }
}

//FLEX
.flex {
  display: flex;
  &.column {
    flex-direction: column;
  }
  &.wrap {
    flex-wrap: wrap;
  }
  &.justify-space-between {
    justify-content: space-between;
  }
  &.justify-end {
    justify-content: flex-end;
  }
  &.align-center {
    align-items: center;
  }
  &.align-end {
    align-items: end;
  }
  &.align-start {
    align-items: start;
  }
}

//PADDINGS AND MARGINS
//PADDING RIGHT
.pr-1 {
  padding-right: 1rem;
}
.pr-2 {
  padding-right: 2rem;
}
.pr-3 {
  padding-right: 3rem;
}
//PADDING TOP
.pt-04 {
  padding-top: 0.4rem;
}
.pt-05 {
  padding-top: 0.5rem;
}
.pt-1 {
  padding-top: 1rem;
}
.pt-2 {
  padding-top: 2rem;
}
.pt-3 {
  padding-top: 3rem;
}

.pt-5 {
  padding-top: 5rem;
}

//PADDING BOTTOM
.pb-02 {
  padding-bottom: 0.2rem;
}
.pb-05 {
  padding-bottom: 0.5rem;
}
.pb-1 {
  padding-bottom: 1rem;
}
.pb-2 {
  padding-bottom: 2rem;
}
.pb-3 {
  padding-bottom: 3rem;
}

.pb-5 {
  padding-bottom: 5rem;
}
//PADDING LEFT
.pl-1 {
  padding-left: 1rem;
}
.pl-2 {
  padding-left: 2rem;
}
.pl-3 {
  padding-left: 3rem;
}

//MARGIN LEFT
.ml-05 {
  margin-left: 0.5rem;
}
//MARGIN TOP
.mt-02 {
  margin-top: 0.2rem;
}
.mt-05 {
  margin-top: 0.5rem;
}
.mt-1 {
  margin-top: 1rem;
}
.mt-1-05 {
  margin-top: 1.5rem;
}
.mt-2 {
  margin-top: 2rem;
}
.mt-3 {
  margin-top: 3rem;
}
.mt-5 {
  margin-top: 5rem;
}

//MARGIN RIGHT
.mr-02 {
  margin-right: 0.2rem;
}
.mr-05 {
  margin-right: 0.5rem;
}
.mr-1 {
  margin-right: 1rem;
}
.mr-2 {
  margin-right: 2rem;
}
.mr-5 {
  margin-right: 9rem;
}

//MARGIN BOTTOM
.mb-05 {
  margin-bottom: 0.5rem;
}
.mb-1 {
  margin-bottom: 1rem;
}
.mb-2 {
  margin-bottom: 2rem;
}
//MARGIN LEFT
.ml-02 {
  margin-left: 0.2rem;
}
.ml-1 {
  margin-left: 1rem;
}
.ml-2 {
  margin-left: 2rem;
}
.ml-5 {
  margin-left: 5rem;
}

//BUTTON
.esgaia-btn {
  background-color: white;
  border: 2px solid rgb(226, 226, 226);
  color: #999;
  white-space: nowrap;
  font-weight: 500;
  text-decoration: none;
  font-family: 'Open Sans', sans-serif;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  height: 2.4rem;
  padding: 0.5rem 1.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.4rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  &:hover {
    text-decoration: none;
    opacity: 0.8;
    border: 2px solid #1f97d1;
    color: #212529;
  }
  &:active:focus,
  &:focus {
    outline: none;
    box-shadow: none !important;
  }
  &.green {
    background-color: #1f97d1;
    color: white;
    border: none;
    &:hover {
      opacity: 0.8;
    }
  }
}

/* Screens smaller than 768px */
@media only screen and (max-width: 950px) {
  .mt-behind-header {
    margin-top: 6.6rem;
  }
  .gradient-white-bg {
    height: 5rem;
  }
  .anchor-block {
    top: -9.7rem;
  }
}
</style>
