<template>
  <div class="box">
    <!-- CASE MODULE TITLE & DESCRIPTION -->
    <div v-if="module.title || module.info">
      <h2 class="mb-1">{{ module.title }}</h2>
      <p class="p-block pb-3">{{ module.info }}</p>
    </div>
    <!-- CASES -->
    <div class="cases-wrapper">
      <div v-for="(c, index) in module.data" :key="index" class="case pt-2 pb-3">
        <div class="flex align-items-center pb-1">
          <p class="case-study-title mr-05">Case study</p>
          <div class="line"></div>
        </div>
        <div class="title-row-small-screens mb-1">
          <h2 v-if="c.title" class="mb-1 mt-1 mr-1">{{ c.title }}</h2>
          <div class="flex wrap align-items-center">
            <p v-if="c.sector" class="pb-1 mr-05">{{ c.sector }}</p>
            <div v-if="c.country" class="pb-1 flex country-wrapper">
              <img class="flag mr-05" :src="require(`../../assets/flags/${formatCountryName(c.country)}.png`)" alt="flag" />
              <p>{{ c.country }}</p>
            </div>
            <p class="pb-1" v-if="!c.country && c.counterparties">
              {{ `${c.counterparties} counterparties engaged` }}
            </p>
          </div>
        </div>
        <div class="parent flex">
          <!-- LEFT-->

          <div class="child left flex column" v-if="c.created_at || c.topic || (c.esgs && c.esgs.length > 0) || c.status || c.progress">
            <!-- CREATED AT -->
            <div v-if="c.created_at" class="info-row flex  justify-space-between pb-1 pl-1 mt-1 pr-1">
              <p class="mr-1">Created</p>
              <p>{{ `${c.created_at.split(' ')[0]}` }}</p>
            </div>
            <!-- TOPIC -->
            <div v-if="c.topic" class="info-row flex  justify-space-between pb-1 pl-1 mt-1 pr-1">
              <p class="mr-1">Topic</p>
              <p>{{ c.topic }}</p>
            </div>
            <!-- ESGS-->
            <div v-if="c.esgs && c.esgs.length > 0" class="info-row flex  justify-space-between pb-1 pl-1 mt-1 pr-1">
              <p class="mr-1" style="padding-top: 7px;">Categories</p>
              <div class="esgs-wrapper flex justify-end wrap mt-02">
                <span
                  class="esg-pill ml-05 mb-05"
                  v-for="(esg, index) in c.esgs"
                  :key="index"
                  v-bind:class="esg.esg"
                  v-bind:style="{ backgroundColor: '#' + esg.color }"
                >
                  <span>{{ esg.name }}</span></span
                >
              </div>
            </div>
            <!-- STATUS -->
            <div v-if="c.status" class="info-row flex  justify-space-between pb-1 pl-1 mt-1 pr-1">
              <p class="mr-1">Status</p>
              <p>{{ c.status.name }}</p>
            </div>
            <!-- PROGRESS -->
            <div v-if="c.progress" class="info-row flex justify-space-between pb-1 pl-1 mt-1 pr-1">
              <p class="mr-1">Progress</p>
              <div class="flex align-items-center">
                <p>{{ `${Math.round(c.progress)}%` }}</p>
                <div class="case-study-progress-bar ml-05 ">
                  <div class="case-study-progress" :style="[{ width: `${c.progress}%` }, { backgroundColor: module.colors[0] }]"></div>
                </div>
              </div>
            </div>
          </div>
          <!-- RIGHT -->
          <div class="child right">
            <div class="title-row-large-screens mb-1">
              <h2 v-if="c.title" class="mb-1 mt-1 mr-1">{{ c.title }}</h2>
              <div class="flex align-items-center">
                <p v-if="c.sector" class="mr-05">{{ c.sector }}</p>
                <div v-if="c.country" class="flex country-wrapper">
                  <img class="flag mr-05" :src="require(`../../assets/flags/${formatCountryName(c.country)}.png`)" alt="flag" />
                  <p>{{ c.country }}</p>
                </div>
                <p v-if="!c.country && c.counterparties">
                  {{ `${c.counterparties} counterparties engaged` }}
                </p>
              </div>
            </div>
            <div v-if="c.description" v-html="c.description" class="description"></div>
          </div>
        </div>
        <!-- EXPAND BUTTON -->
        <!--   <button class="arrow-down-btn mt-2"><i class="arrow"></i></button> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['module'],
  data() {
    return {};
  },
  methods: {
    formatCountryName(country) {
      const countryName = country.toLowerCase();
      return countryName.replace(/\s/g, '-');
    },
  },
};
</script>
<style lang="scss" scoped>
.case {
  /*   border-top: 2px solid #eaeaea; */
}
.description ::v-deep h2 {
  font-size: 0.875rem;
  letter-spacing: 0.02rem;
  margin: 1rem 0;
}
.description ::v-deep p,
.description ::v-deep span,
.description ::v-deep strong,
.description ::v-deep em,
.description ::v-deep u,
.description ::v-deep s,
.description ::v-deep strong {
  font-family: 'Open Sans', sans-serif;
  line-height: 1.8;
  letter-spacing: 0.02rem;
  font-size: 0.875rem;
  white-space: pre-line;
}
.description ::v-deep strong {
  font-weight: 600;
}
.case-study-title {
  font-weight: 700;
  color: #1f97d1;
  white-space: nowrap;
}
.line {
  height: 2px;
  width: 100%;
  background-color: #eaeaea;
  margin-top: 10px;
  margin-bottom: 7px;
}
.title-row-large-screens {
  display: block;
}
.title-row-small-screens {
  display: none;
}
/* PARENT */
.parent {
  justify-content: space-between;
}
/* LEFT */
.left {
  width: 40%;
  min-width: 400px;
  padding-right: 50px;
}
.info-row {
  justify-content: space-between;
  align-items: flex-start;
  border-bottom: 1px solid #eaeaea;
}
.info-row:last-of-type {
  border-bottom: none;
}
.country-wrapper {
  align-items: center;
}
.flag {
  width: 1rem !important;
  height: 1rem !important;
  margin-top: 0;
}
/* RIGHT */
.right {
  width: 100%;
}
.progress-bar-wrapper {
  align-items: center;
}
.case-study-progress-bar {
  position: relative;
  display: inline-block;
  height: 10px;
  width: 150px;
  border-radius: 5px;
  background-color: #e6e6e6;
  margin-top: 4px;
  .case-study-progress {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 10px;
    border-radius: 5px;
  }
}
.esgs-wrapper {
  min-height: 24px;
}
.esg-pill {
  display: inline-block;
  background-color: #1f97d1;
  text-align: center;
  padding: 4px 8px;
  border-radius: 25px;
  color: white;
  font-size: 13px !important;
  white-space: nowrap;
  line-height: 18px;
  > span {
    font-size: 13px !important;
  }
}
/* ARROW DOWN */
.arrow-down-btn {
  border: 2px solid #e2e2e2;
  background-color: white;
  white-space: nowrap;
  font-weight: 500;
  text-decoration: none;
  font-family: 'Open Sans', sans-serif;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  height: 2.4rem;
  padding: 0.4rem 0.8rem 0.8rem 0.8rem;
  border-radius: 0.4rem;
  .arrow {
    border: solid #999999;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }
  &:hover {
    cursor: pointer;
    border: 2px solid #1f97d1;
    .arrow {
      border: solid #000000;
      border-width: 0 2px 2px 0;
    }
  }
}

/* Screens smaller than 1024px */
@media only screen and (max-width: 64em) {
  .parent {
    flex-wrap: wrap;
  }
  .left {
    width: 100% !important;
    min-width: unset;
    padding-right: 0;
  }
  .right {
    text-align: left;
    align-items: start;
    width: 100% !important;
  }

  .line {
    background-color: #d5d5d5;
  }
  .title-row-large-screens {
    display: none;
  }
  .title-row-small-screens {
    display: block;
  }

  .info-row:last-of-type {
    padding-bottom: 2rem;
  }
}

/* Screens smaller than 400px */
@media only screen and (max-width: 400px) {
  .description {
    padding-bottom: 1rem !important;
  }
  .title-row-small-screens {
    > h2 {
      margin-top: 0.5rem !important;
    }
  }
  .case {
    padding-bottom: 2rem !important;
  }
  .case:last-of-type {
    padding-bottom: 1rem !important;
  }
  .case-study-progress-bar {
    width: 100px;
  }
  .info-row {
    margin-top: 0.5rem !important;
    padding: 0 0 0.25rem 0 !important;
    flex-wrap: wrap;
    > p {
      padding-bottom: 0.25rem;
    }
  }

  .info-row:last-of-type {
    padding-bottom: 2rem !important;
  }
}

/* Screens smaller than 300px */
@media only screen and (max-width: 300px) {
  .case-study-progress-bar {
    width: 80px;
  }

  .info-row:last-of-type {
    padding-bottom: 2rem !important;
  }
}
</style>
