<template>
  <div class="box">
    <h2 class="mb-1">{{ module.title }}</h2>
    <div class="flex parent mb-1">
      <div class="child left">
        <p class="p-block">{{ module.info }}</p>
      </div>
      <div class="child right">
        <div v-if="showTopFivePais">
          <div
            class="pai flex align-center pb-1 pl-1"
            :class="{ 'mt-1': index > 0, noBorderBottom: index === 4 }"
            v-for="(pai, index) in sortPais.slice(0, 5)"
            :key="index"
          >
            <p class="pr-1 number" :style="{ color: module.colors[0] ? module.colors[0] : '#1f97d1' }">{{ pai.amount }}</p>
            <p>{{ pai.name }}</p>
          </div>
        </div>
        <div v-else>
          <div
            class="pai flex align-center pb-1 pl-1"
            style="color: module.color;"
            :class="{
              'mt-1': index > 0,
              noBorderBottom: index === sortPais.length - 1,
            }"
            v-for="(pai, index) in sortPais.slice(0, 20)"
            :key="index"
          >
            <p class="pr-1 number" :style="{ color: module.colors[0] ? module.colors[0] : '#1f97d1' }">{{ pai.amount }}</p>
            <p>{{ pai.name }}</p>
          </div>
        </div>
        <div class="info-more-20 flex mt-1 pl-1 pb-1" v-if="sortPais.length > 20">
          <img class="mr-02" src="../../assets/info.svg" alt="info" />
          <p>Showing top 20</p>
        </div>
      </div>
    </div>
    <button @click="toggleMorePais" v-if="sortPais.length > 5" class="esgaia-btn">
      {{ `Show ${showTopFivePais ? 'more' : 'less'}` }}
    </button>
  </div>
</template>

<script>
export default {
  props: ['module'],
  data() {
    return {
      showTopFivePais: true,
    };
  },
  computed: {
    sortPais() {
      const sortedPais = this.module.data;

      sortedPais.sort((firstPais, secondPais) => secondPais.amount - firstPais.amount);

      const sortedPais2 = sortedPais.filter((p) => p.amount > 0);
      return sortedPais2;
    },
  },
  methods: {
    toggleMorePais() {
      this.showTopFivePais = !this.showTopFivePais;
    },
  },
};
</script>

<style scoped lang="scss">
.parent {
  gap: 2rem;
}

.left {
  width: 40%;
}

.right {
  width: 60%;
  box-sizing: border-box;
}

.pai {
  width: 95%;
  border-bottom: 1px solid #eaeaea;
  margin-left: auto;
  margin-right: auto;
  &.noBorderBottom {
    border-bottom: none;
  }
}

.number {
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  font-size: 2rem;
  text-align: center;
  color: #1f97d1;
  min-width: 4.5rem;
  letter-spacing: 0.1rem;
}

.info-more-20 {
  width: 95%;
  p {
    font-size: 12px;
    color: #999999;
  }
  img {
    height: 1rem;
    width: 1rem;
  }
}
/* Screens smaller than 1024px */
@media only screen and (max-width: 64em) {
  .parent {
    flex-wrap: wrap;
    gap: 3rem;
  }
  .child {
    width: 100%;
  }

  .right {
    margin-bottom: 1rem;
    padding-left: 0;
  }
  .pai {
    margin-left: 0;
    width: auto;
  }
}

/* Screens smaller than 400px */
@media only screen and (max-width: 400px) {
  .pai {
    padding-left: 0 !important;
  }
}
</style>
