<template>
  <div class="box">
    <h2 class="mb-1">{{ module.title }}</h2>
    <p class="p-block">{{ module.info }}</p>
    <div class="flex mt-1">
      <One-number :class="`index-${index}`" :statistic="statistic" v-for="(statistic, index) in data.statistics" :key="index" :order="index"/>
    </div>
  </div>
</template>



<script src="https://cdnjs.cloudflare.com/ajax/libs/gsap/3.2.4/gsap.min.js"></script>
<script>
import OneNumber from './OneNumber.vue'
export default {
 
  components: {
    OneNumber
  },
  props: ["module"],
  
  data() {
    return {
      data: [],
    };
  },
  mounted(){
    this.data = this.module;
    this.data.statistics = this.data.statistics.filter((element) => {return element.show == true || element.show == null})
  }
};
</script>

<style  scoped lang="scss">
.flex {
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 2rem;
}
</style>