<template>
  <div class="box">
    <h2 class="mb-1">{{ module.title }}</h2>
    <div class="parent flex mb-1">
      <div class="child left">
        <p class="p-block">{{ module.info }}</p>
      </div>
      <div class="child right">
        <div v-if="showTopFiveSDGS">
          <div
            class="sdg flex align-center pb-1 pl-1"
            :class="{ 'mt-1': index > 0, noBorderBottom: index === 4 }"
            v-for="(sdg, index) in this.sortSDGS.slice(0, 5)"
            :key="index"
          >
            <img class="mr-1" :src="`https://auth.esgaia.com/images/sdgs/${sdg.id}.png`" :alt="sdg.title" />

            <p class="pr-1">{{ formatName(sdg.name) }}</p>
            <p class="pr-1 percentage">{{ `${sdg.percent} %` }}</p>
          </div>
        </div>
        <div v-else>
          <div
            class="sdg flex align-center pb-1 pl-1"
            :class="{
              'mt-1': index > 0,
              noBorderBottom: index === sortSDGS.length - 1,
            }"
            v-for="(sdg, index) in this.sortSDGS.slice(0, 20)"
            :key="index"
          >
            <img class="mr-1" :src="`https://auth.esgaia.com/images/sdgs/${sdg.id}.png`" :alt="sdg.title" />

            <p class="pr-1">{{ formatName(sdg.name) }}</p>
            <p class="pr-1 percentage">{{ `${sdg.percent} %` }}</p>
          </div>
        </div>
      </div>
    </div>
    <button @click="toggleMoreSDGS" v-if="sortSDGS.length > 5" class="esgaia-btn">
      {{ `Show ${showTopFiveSDGS ? 'more' : 'less'}` }}
    </button>
  </div>
</template>

<script>
export default {
  props: ['module'],
  data() {
    return {
      showTopFiveSDGS: true,
    };
  },
  computed: {
    sortSDGS() {
      //calcuate percent for each statitic
      let _this = this;
      this.module.sdgs.forEach((s) => (s.percent = parseFloat(100 * (s.amount / _this.module.amount_engagements)).toFixed(1)));
      //sort higest to lowest percent
      const sortedIssues = this.module.sdgs;
      sortedIssues.sort((firstIssue, secondIssue) => secondIssue.percent - firstIssue.percent);
      const sortedIssues2 = sortedIssues.filter((s) => s.percent > 0);
      return sortedIssues2;
    },
  },
  methods: {
    toggleMoreSDGS() {
      this.showTopFiveSDGS = !this.showTopFiveSDGS;
    },
    formatName(name) {
      name = name.substring(name.indexOf('.') + 1);
      return name;
    },
  },
};
</script>

<style scoped lang="scss">
.parent {
  gap: 2rem;
}

.left {
  width: 60%;
}

.right {
  width: 40%;
  box-sizing: border-box;
}

.percentage {
  white-space: nowrap;
}
.sdg {
  width: 95%;
  border-bottom: 1px solid #eaeaea;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  &.noBorderBottom {
    border-bottom: none;
  }
  :last-child {
    margin-left: auto;
  }
}

img {
  width: 3rem;
  height: 3rem;
  background-color: lightgrey;
  border-radius: 0.2rem;
}

.info-more-20 {
  width: 95%;
  p {
    font-size: 12px;
    color: #999999;
  }
  img {
    height: 1rem;
    width: 1rem;
  }
}

/* Screens smaller than 1024px */
@media only screen and (max-width: 64em) {
  .parent {
    flex-wrap: wrap;
    gap: 3rem;
  }
  .child {
    width: 100%;
  }

  .right {
    margin-bottom: 1rem;
    padding-left: 0;
  }
  .sdg {
    margin-left: 0;
    width: auto;
  }
}

/* Screens smaller than 400px */
@media only screen and (max-width: 400px) {
  .percentage {
    padding-right: 0 !important;
  }
  .sdg {
    padding-left: 0 !important;
  }
  img {
    width: 2rem;
    height: 2rem;
  }
}
</style>
