<template>
  <div class="box">
    <h2 class="mb-1">{{ module.title }}</h2>
    <div class="parent flex mb-1">
      <div class="child left">
        <p class="p-block">{{ module.info }}</p>
      </div>

      <div class="child right pr-2">
        <EngagementProgressChart v-if="data.length > 0" :labels="module.labels" :backgroundColors="module.colors" :data="this.data"></EngagementProgressChart>
        <div class="mt-2 pb-02 info">
          <span class="dot" :style="{ 'background-color': module.colors.length > 0 ? module.colors[0] : ['#1f97d1'] }"></span>
          <span class="description-title">Positive progress</span>
        </div>
        <div class="info">
          <span class="dot no-change"></span>
          <span class="description-title">No change</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EngagementProgressChart from './charts/EngagementProgressChart';
export default {
  components: {
    EngagementProgressChart,
  },
  props: ['module'],
  data() {
    return {
      data: [],
    };
  },
  mounted() {
    this.data = [
      [this.module.data['E']['amount'], this.module.data['S']['amount'], this.module.data['G']['amount'], this.module.data['N/A']['amount']],
      [this.module.data['E']['total'], this.module.data['S']['total'], this.module.data['G']['total'], this.module.data['N/A']['total']],
    ];
  },
};
</script>

<style scoped lang="scss">
.parent {
  gap: 2rem;
}
.left {
  width: 60%;
}
.right {
  width: 40%;
}

/* Screens smaller than 1024px */
@media only screen and (max-width: 64em) {
  .parent {
    flex-wrap: wrap;
    gap: 0;
  }
  .child {
    width: 100%;
  }

  .right {
    margin-top: 3rem;
    padding-right: 15px;
  }
}
.description {
  padding-top: 15px;
}

.info {
  margin-left: 90px;
}
.dot {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 6px;
  &.no-change {
    background-color: #e8e8e8;
  }
}
</style>
