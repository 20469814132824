<template>
  <div class="mt-2 mb-2">
    <p class="number" :style="{color: statistic.color ? statistic.color : '#1f97d1'}">
      {{ animatedNumber }}
    </p>
    <p class="text">{{statistic.text}}</p>
  </div>
</template>

<script>
import { gsap } from "gsap";

export default {
  props: ["statistic", 'order'],
  data() {
    return {
      quantity: 0,
      number: 0,
      tweenedNumber: 0,
    };
  },

 
  watch: {
    quantity: function () {
      this.quantity = this.statistic.quantity;
    },
  },
  computed: {
    animatedNumber: function () {
      return (this.tweenedNumber.toFixed(0));
    },
  },
  mounted() {
    this.quantity = this.statistic.quantity;
    gsap.to(this.$data, {
      duration: 1,
      tweenedNumber: this.quantity,
    });
  },
};
</script>

<style scoped lang="scss">
div {
  text-align: center;
  box-sizing: border-box;
}
.number {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 4rem;
  letter-spacing: 0.2rem;
}
.index-0 > .number{
color: #be1e19;
}
.index-1 > .number{
color: #404040;
}

.index-2 > .number{
color: #766755;
}
.index-3 > .number{
color: #81a340;
}



/* Screens smaller than 480px */
@media only screen and (max-width: 30em) {
 .number{ 
  font-size: 2.5rem;
  letter-spacing: 0.2rem;
}
.text {
  font-size: .8rem;
}
div {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
 }

</style>