<template>
  <MapChart
    :countryData="this.cdata ? this.cdata : {}"
    :highColor="this.colors.length > 0 ? this.colors[0] : '#1f97d1'"
    lowColor="#eaeaea"
    countryStrokeColor="#fffff"
    defaultCountryFillColor="#eaeaea"
    chromaScaleOn="true"
    showLegend="true"
  />
</template>

<script>
import MapChart from 'vue-map-chart';

export default {
  components: { MapChart },
  props: {
    country_data: Object,
    countries: Array,
    colors: Array,
  },
  data() {
    return {
      cdata: {},
    };
  },
  methods: {
    sortCountries: function() {
      let countries = this.countries;
      const filteredCountries = countries.filter((c) => c.count != 0);
      const sortedCountries = filteredCountries.sort((a, b) => b.count - a.count);
      return sortedCountries.slice(0, 5);
    },
  },
  watch: {
    countryData(newValue) {
      this.cdata = newValue;
    },
  },
  mounted() {
    this.cdata = this.country_data;
  },
};
</script>
