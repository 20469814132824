<template>
  <div class="flex align-center mb-2 mt-2" :class="[{ center: center }, { 'justify-end': !center }]">
    <p class="mr-02 pb-02">Powered by</p>
    <a href="https://www.esgaia.com/" target="_blank"> <img class="logo pt-04" src="../assets/esgaia-logo.svg" alt="esgaia logo" width="110"/></a>
  </div>
</template>

<script>
export default {
  props: ['center'],
  name: 'App-footer',
};
</script>

<style scoped lang="scss">
.logo {
  width: 5rem;
  height: 100%;
  padding-right: 1.875rem;
}
.center {
  justify-content: center;
}
</style>
