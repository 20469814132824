<template>
  <div class="box">
    <h2 class="mb-1">{{ module.title }}</h2>
    <div class="parent flex mb-1">
      <div class="child left">
        <p class="p-block">{{ module.info }}</p>
      </div>
      <div class="child right">
        <ChartESGCategories :data="module" />
      </div>
    </div>
  </div>
</template>

<script>
import ChartESGCategories from './charts/ESGCategoriesChart.vue';

export default {
  components: {
    ChartESGCategories,
  },
  props: ['module'],
  data() {
    return {};
  },
};
</script>

<style scoped lang="scss">
.left {
  width: 60%;
}
.right {
  width: 40%;
}

/* Screens smaller than 1024px */
@media only screen and (max-width: 64em) {
  .parent {
    flex-wrap: wrap;
  }
  .child {
    width: 100%;
  }

  .right {
    margin: 3rem auto 0 auto;
    width: 90%;
  }
}

/* Screens smaller than 800px */
@media only screen and (max-width: 800px) {
  .right {
    width: 100%;
  }
}
</style>
