<template>
  <div class="spinner">
    <div class="lds-ring" :class="{ 'small-spinner': small, 'button-spinner': button }">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
    <p>
      <slot></slot>
    </p>
  </div>
</template>

<script>
export default {
  props: ['size', 'small', 'button'],
  methods: {},
  mounted() {},
};
</script>

<style lang="scss" scoped>
.theme-list-component .lds-ring {
  display: inline-block;
  position: relative;
  width: 80px !important;
  height: 80px !important;
  div {
    width: 60px !important;
    height: 60px !important;
    border-width: 8px !important;
  }
}
.lds-ring {
  div {
    box-sizing: border-box;
    display: block;
    background-color: rgba(255, 255, 255, 0);
    position: absolute;
    width: 140px;
    height: 140px;
    margin: 16px;
    border: 16px solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #1f97d1 transparent transparent transparent;
  }
  &.small-spinner {
    width: 30px;
    height: 30px;
    border: 4px solid #fff;

    div {
      height: 30px;
      width: 30px;
      margin: 0px;
      border: 4px solid #fff;
      border-color: #1f97d1 transparent transparent transparent;
    }
  }
  &.button-spinner {
    width: 21px !important;
    height: 21px !important;
    div {
      width: 18px !important;
      height: 18px !important;
      margin: 0;
      border-width: 2px !important;
      border-color: #ffffff transparent transparent transparent;
    }
  }
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 140px;
  height: 140px;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
